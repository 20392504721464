/* eslint-disable new-cap */
import axios from "axios"
import L from "leaflet"
import "leaflet.markercluster/dist/leaflet.markercluster"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import "leaflet.fullscreen"
import "leaflet.fullscreen/Control.FullScreen.css"
import _ from 'lodash'

require("normalize.css/normalize.css")
require("../css/main.css")
require("./page.css")

const accessToken = "pk.eyJ1IjoiaWcxbmEiLCJhIjoiY2t3a2d6aXRzMXMwZjJvcXZjcGdqMzY1ayJ9.b3xAKBYKYXkkJUkeXOcupA"

async function setup () {
  const map = new L.map("map", {
    zoomSnap: 0.2
  }).fitBounds([[50.917, -5.58],[42.522,9.36]])

  L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    {
      attribution:
        "Map data &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors, Imagery © <a href=\"https://www.mapbox.com/\">Mapbox</a>",
      maxZoom: 18,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
      accessToken
    }
  ).addTo(map)

  /**
   * Clear search results
   */
   const clearResults = document.getElementById('clear-search');
   clearResults.addEventListener('click', () => {
     searchBar.value = '';
     map.fitBounds([[50.917, -5.58],[42.522,9.36]])
   })

  const searchBar = document.getElementById('search')
  searchBar.addEventListener('input', _.debounce(async function() {
    const georesultsList = document.getElementById('georesults')
    georesultsList.innerHTML = '';
    const text = searchBar.value
    const result = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${accessToken}`)
    const features = result.data.features
    if(searchBar.value !== '') {
      clearResults.classList.remove('invisible')
    } else {
      clearResults.classList.add('invisible')
    }
    
    if(features && features.length) {
      features.forEach((location, index) => {

        const option = document.createElement('span')
        option.classList.add('georesult-option')
        option.textContent = location.place_name

        option.addEventListener('click', () => {
          searchBar.value = features[index].place_name
          georesultsList.innerHTML = ''
          map.setView([features[index].center[1], features[index].center[0]], 10)
        })

        georesultsList.append(option)
      })
    }
  }, 300))
  
  

  const locateMeElem = document.getElementById("locate-me")
  locateMeElem.addEventListener("click", async (event) => {
    await navigator.geolocation.getCurrentPosition(async (position) => {
      map.setView([position.coords.latitude, position.coords.longitude], 10)
    })
  })

  const getPartners = await axios.get("https://api.azfalte.com/v1/partners")
  const partners = getPartners.data.features
  const organizations = []
  partners.forEach((partner) => {
    const organization = partner.properties.partner
    if (!organizations.includes(organization)) { organizations.push(partner.properties.partner) }
  })
  organizations.sort()

  const titleElem = document.getElementById("map-title")
  titleElem.innerHTML = `Faites confiance à nos <strong>${partners.length} magasins partenaires</strong> :`

  const filterPartnerElem = document.getElementById("partner-filter")
  organizations.forEach((organization) => {
    filterPartnerElem.appendChild(new Option(organization, organization))
  })
  filterPartnerElem.addEventListener("change", (event) => {
    selectedPartner = event.target.value
    filteredPartners = getFilteredPartners()
    setPartnersMarkers(filteredPartners, map, markers)
  })

  let selectedPartner = filterPartnerElem.value
  const getFilteredPartners = () => {
    return partners.filter((partner) => {
      return selectedPartner !== "null"
        ? partner.properties.partner === selectedPartner
        : true
    })
  }

  const markers = L.markerClusterGroup({ maxClusterRadius: 40 })
  let filteredPartners = getFilteredPartners()
  setPartnersMarkers(filteredPartners, map, markers)
}

function setPartnersMarkers (partners, map, markers) {
  map.removeLayer(markers)
  markers.clearLayers()
  partners.forEach((partner) => {
    const marker = L.marker(
      [partner.geometry.coordinates[1], partner.geometry.coordinates[0]],
      {
        title: partner.properties.title
      }
    )
    const popup = L.popup().setContent(
      `<a href='${partner.properties.link}' target='_blank'>${partner.properties.title}</a>`
    )
    marker.bindPopup(popup).openPopup()

    markers.addLayer(marker)
  })
  map.addLayer(markers)
}

setup()
